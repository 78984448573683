export default function Arrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="39" fill="none">
      <path
        fill="#fff"
        d="M19.219.182h-4.801V29.21L3.32 18.112.033 21.436l16.785 16.786 16.823-16.786-3.361-3.324L19.219 29.21V.182Z"
      />
    </svg>
  );
}
