import simroomImg from "../assets/images/simroom.jpg";
import samsungImg from "../assets/images/samsung.jpg";
import washcomboImg from "../assets/images/washcombo.jpg";
import webosImg from "../assets/images/webos.jpg";

export default function Work() {
  const list = [
    {
      title: "LG TV SimRoom",
      image: simroomImg,
      description:
        "An interactive LG experience that provides a virtual tour of the product. Featuring step-by-step guide and OS emulator.",
      technology: "Next.js, SASS",
      link: "https://www.lg.com/us/simroom/tvs/oled-g3-series/showroom",
    },
    {
      title: "Samsung TV Simualtor",
      image: samsungImg,
      description:
        "Interactive web simulator for Samsung Smart TVs. Users can select their TV models and go through different features to experience and learn how to use the product",
      technology: "React.js, SASS",
      link: "https://www.samsung.com/us/support/simulators/",
    },
    {
      title: "LG WashComboTM Microsite",
      image: washcomboImg,
      description:
        "Microsite built for the LG US to promote their new WashComboTM. Led a team of 3 developers, and worked closely with LG's development, Measurement Operations, and design team.",
      technology: "Next.js, SASS",
      link: "https://www.lg.com/us/washcombo-all-in-one",
    },
    {
      title: "webOS Simualtor/Emulator",
      image: webosImg,
      description:
        "A smart TV OS simulator and emulator, including interactive step-by-step guide for different features and overview of remotes and the OS",
      technology: "Next.js, SASS",
      link: "https://webos.developer.lge.com/webos-hub/emulator-20s",
    },
  ];
  return (
    <div className="work" id="work">
      {list.map((el, i) => (
        <div className="work-container" key={i}>
          <div className="work-container--copy">
            <h4>
              0{i + 1} / {el.title}
            </h4>
            <p className="description">{el.description}</p>
            <p className="technology">{el.technology}</p>
            <div className="link">
              <a href={el.link} target="_blank" rel="noopener noreferrer">
                {el.link}
              </a>
            </div>
          </div>
          <div className="img">
            <img src={el.image} alt="" />
          </div>
        </div>
      ))}
    </div>
  );
}
