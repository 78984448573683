import Footer from "./components/Footer";
import Main from "./components/Main";
import Nav from "./components/Nav";
import Work from "./components/Work";
import "./styles/styles.scss";
import { ReactLenis } from "@studio-freight/react-lenis";

function App() {
  return (
    <ReactLenis root>
      <div className="wrapper">
        <Nav />
        <Main />
        <Work />
        <Footer />
      </div>
    </ReactLenis>
  );
}

export default App;
