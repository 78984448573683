export default function Email() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 216 216"
    >
      <path
        fill="#ffffff"
        d="M108 0C48.353 0 0 48.353 0 108s48.353 108 108 108 108-48.353 108-108S167.647 0 108 0zm48.657 60L107.96 98.498 57.679 60h98.978zm5.01 96h-109V76.259l50.244 38.11c1.347 1.03 3.34 1.545 4.947 1.545 1.645 0 3.073-.54 4.435-1.616l49.374-39.276V156z"
      />
    </svg>
  );
}
