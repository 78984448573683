import Arrow from "../icons/Arrow";

export default function Main() {
  return (
    <div className="main" id="main">
      <header>
        <h1>Hello 👋 I&prime;m Yeana.</h1>
        <a href="#work">
          <Arrow fill="#ffffff" />
        </a>
      </header>
      <div className="intro">
        An experienced front-end developer based in Dallas, Texas, currently @{" "}
        <a
          href="https://www.outpave.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Outpave
        </a>
        , specializes in mobile development with{" "}
        <span className="react">React Native</span> and{" "}
        <span className="sass">GraphQL</span>.
      </div>
    </div>
  );
}
