export default function Nav() {
  return (
    <nav className="nav">
      <div className="logo">
        <a href="#main">yeana.dev</a>
      </div>
      <ul className="list">
        <li>
          <a href="#work">Work</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
        <li>
          <a
            href="https://drive.google.com/file/d/18YeYhITj6Wen-75FoWNjwAc27j82MoLb/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Resume
          </a>
        </li>
      </ul>
    </nav>
  );
}
