import LinkedIn from "../icons/LinkedIn";
import Github from "../icons/Github";
import Email from "../icons/Email";

export default function Footer() {
  const links = [
    {
      link: "https://linkedin.com/in/yeanacho",
      title: "LinkedIn",
      svg: <LinkedIn />,
    },
    {
      link: "https://github.com/yeana-dev",
      title: "Github",
      svg: <Github />,
    },
    {
      link: "mailto:yeana.dev@gmail.com",
      title: "Email",
      svg: <Email />,
    },
  ];
  return (
    <footer>
      <div className="logo">
        <h3>Yeana Cho ⏤ 2024</h3>
      </div>
      <ul className="links">
        {links.map((el, i) => (
          <li key={i}>
            <a
              href={el.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {el.svg}
            </a>
          </li>
        ))}
      </ul>
    </footer>
  );
}
